import React from 'react';
import { Button, Flex, InputNumber, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CartItem } from '../../types';
import { Link } from 'gatsby';
import { cartItemKey } from './useCart';
import { formatPrice } from '../../utils/formatters';
import { GatsbyImage } from 'gatsby-plugin-image';

type Props = {
  total: number;
  items: CartItem[];
  updateQuantity: (item: CartItem) => void;
  removeItem: (item: CartItem) => void;
};

export const CartIconContent = ({
  total,
  items,
  updateQuantity,
  removeItem,
}: Props) => {
  if (items.length === 0)
    return (
      <Typography.Text>Нямате добавени продукти в количката</Typography.Text>
    );

  return (
    <Flex
      vertical
      className="gap-2"
      style={{ maxHeight: 400, overflow: 'auto' }}
    >
      {items.map((item) => (
        <Flex key={cartItemKey(item)} className="gap-4">
          <GatsbyImage
            image={item.product.previewImage.childImageSharp.gatsbyImageData}
            alt={item.product.title}
            style={{ width: 100, height: 100 }}
          />
          <Flex vertical className="gap-2">
            <Link to={`/products/${item.product.slug}`}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {item.product.title}
              </Typography.Title>
            </Link>
            <Flex className="gap-2">
              <Typography.Text>
                Размер: <Typography.Text strong>{item.size}</Typography.Text>
              </Typography.Text>
              {item.color && (
                <Typography.Text>
                  Цвят: <Typography.Text strong>{item.color}</Typography.Text>
                </Typography.Text>
              )}
            </Flex>
            <Flex className="gap-2">
              <InputNumber
                value={item.quantity}
                onChange={(quantity) => {
                  if (!quantity) return;
                  updateQuantity({ ...item, quantity });
                }}
              />

              <Button danger onClick={() => removeItem(item)}>
                <DeleteOutlined />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ))}

      {items.length > 0 && (
        <Flex align="center" justify="space-between">
          <p>
            Общо: <span className="font-bold">{formatPrice(total)}</span>
          </p>

          <Link to="/checkout">
            <Button type="primary">Поръчай</Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
