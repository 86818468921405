import { useMutation, useQuery } from '@tanstack/react-query';
import { Cart, CartItem } from '../../types';
import { useMemo } from 'react';
import { queryClient } from '../../common/client';

const getCart = (): Cart => {
  const cart = localStorage.getItem('cart');
  return cart ? (JSON.parse(cart) as Cart) : { items: [] };
};

export const cartItemKey = (item: CartItem) =>
  `${item.product.slug}-${item.size}-${item.color}`;

export const useCart = () => {
  const { data } = useQuery({
    queryKey: ['cart'],
    queryFn: () => getCart(),
  });

  const { mutate: reset } = useMutation({
    mutationKey: ['cart'],
    mutationFn: async () => {
      localStorage.removeItem('cart');
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
  });

  const { mutate: addItem } = useMutation({
    mutationKey: ['cart'],
    mutationFn: async (item: CartItem) => {
      const cart = getCart();
      const index = cart.items.findIndex(
        (i) => cartItemKey(i) === cartItemKey(item),
      );
      if (index !== -1) {
        cart.items[index].quantity += item.quantity;
      } else {
        cart.items.push(item);
      }
      localStorage.setItem('cart', JSON.stringify(cart));
      queryClient.invalidateQueries({ queryKey: ['cart'] });
      return cart;
    },
  });

  const { mutate: updateQuantity } = useMutation({
    mutationKey: ['cart'],
    mutationFn: async (item: CartItem) => {
      const cart = getCart();
      const index = cart.items.findIndex(
        (i) => cartItemKey(i) === cartItemKey(item),
      );
      if (index !== -1) {
        cart.items[index].quantity = item.quantity;
        localStorage.setItem('cart', JSON.stringify(cart));
      }
      queryClient.invalidateQueries({ queryKey: ['cart'] });

      return cart;
    },
  });

  const { mutate: removeItem } = useMutation({
    mutationKey: ['cart'],
    mutationFn: async (item: CartItem) => {
      const cart = getCart();
      const index = cart.items.findIndex(
        (i) => cartItemKey(i) === cartItemKey(item),
      );

      if (index !== -1) {
        cart.items.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(cart));
      }

      queryClient.invalidateQueries({ queryKey: ['cart'] });
      return cart;
    },
  });

  const items = useMemo(() => {
    return data?.items || [];
  }, [data]);

  const total = useMemo(() => {
    return items.reduce((acc, item) => acc + item.quantity * item.price, 0);
  }, [items]);

  return { items, total, reset, addItem, updateQuantity, removeItem };
};
